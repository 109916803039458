

























































































































import { Vue, Component } from 'vue-property-decorator'
import { courses } from '@/lib/courses'
import CenterContent from '@/components/CenterContent.vue'

@Component({
	components: {
		CenterContent,
	},
})
export default class App extends Vue {
		public courses = courses
}
