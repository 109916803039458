export const courses = [{
	title: 'Geburtsvorbereitungskurs',
	subtitle: 'Ein Ort zum Wohlfühlen und Kontakte knüpfen.',
	slug: 'geburtsvorbereitungskurs',
	summary: `Jede Geburt ist anders. Atem- und Körperwahrnehmungsübungen gehören ebenso zum Kurs wie Informationen
	und praktische Tipps sowie Gespräche in der Gruppe.`,
	description: `<p>
		Ich habe für die Abende verschiedene Themen geplant und werde Informationen um Schwangerschaft,
		Geburt, Stillen und Pflege des Babys weitergeben. Der gemeinsame Austausch wird dabei nicht zu kurz kommen.
		Atem -und Entspannungsübungen werden helfen, sich gut auf die Geburt vorzubereiten.<br>
		Bei Fragen oder Problemen dürfen Sie sich gerne an mich wenden.
	</p>
	<p>
		In den Kursabenden eingebunden sind Gymnastikübungen zur Kreislaufanregung, Kräftigung und Förderung der Beweglichkeit.
		Mit kleinen Massagen werden wir uns gegenseitig verwöhnen und vom Alltag erholen.
		Zwei Partnerabende werden angeboten, an welchen die Männer herzlich willkommen sind.
	</p>
	An einem Abend werden Sie sich gemeinsam auf die Geburt einstimmen.
	Es wird über den normalen Ablauf einer Geburt gesprochen und wie der Partner fürsorglich, die Gebärende
	unterstützen kann. Dazu gehören u.a. der Umgang mit Wehenschmerzen und das Kennenlernen von Geburtspositionen.
	Für den zweiten Partnerabend ist das Thema Säuglingspflege geplant (Tragen, Wickeln,
	Baden, Schlaf, Leben mit dem Kind).
	An diesem Kurs werden etwa 10 Frauen teilnehmen.
	Gemeinsam werden Sie sich auf die Geburt und die Zeit danach vorbereiten.
	Ich möchte Ihnen helfen, mit Vertrauen und Mut dem Mutterwerden und der Veränderung entgegenzusehen.`,

	schedule: [
		"19:30-21:00 Uhr",
	],

	city: 'Weil im Schönbuch',
	address: 'Kurze Straße 1',
	locationImage: 'weil.jpg',

	preview: 'vorbereitung_preview.jpg',
	image: 'vorbereitung.jpg',
}, {
	title: 'Rückbildungsgymnastik',
	subtitle: 'Bewegung und Entspannung.',
	slug: 'rueckbildungsgymnastik',
	summary: 'Bei flotter Musik und abwechslungsreichen Übungen mit Bällen und Bändern bringen wir den Körper und die Seele in Schwung.',
	description: `Hier ist Zeit und Raum für Bewegung und Entspannung. Bei flotter Musik und abwechslungsreichen Übungen
	mit Bällen und Bändern bringen wir den Körper und die Seele in Schwung. Hier finden Sie Linderung für Rückenschmerzen
	und Nackenverspannungen und kräftigende Übungen für den Beckenboden. Sie treffen andere Mütter und haben die
	Möglichkeit, sich  mit ihnen auszutauschen.
	<br>
	Der beste Zeitpunkt mit einem Kurs zu beginnen, ist nach einer Spontangeburt etwa vier Wochen danach. Hatten Sie
	einen Kaiserschnitt, dürfen Sie sich gerne etwas länger Zeit lassen. Grundsätzlich sollten Sie sich wohl fühlen
	und der Babyalltag sich  eingespielt haben.
	Der Kurs findet montags 18.00-19.15 Uhr statt und umfasst jeweils 8 Termine.`,

	schedule: [
		"18:00-19:15 Uhr"
	],

	city: 'Weil im Schönbuch',
	address: 'Kurze Straße 1',
	locationImage: 'weil.jpg',

	preview: 'rueckbildung_preview.jpg',
	image: 'rueckbildung.jpg',
}, {
	title: 'Rückbildungsgymnastik Gärtringen',
	subtitle: 'Bewegung und Entspannung.',
	slug: 'rueckbildungsgymnastik-gaertringen',
	summary: 'Flotte Musik und abwechslungsreichen Übungen mit Bällen und Bändern. Der Kurs findet in Gärtringen statt.',
	description: `Hier ist Zeit und Raum für Bewegung und Entspannung. Bei flotter Musik und abwechslungsreichen Übungen
	mit Bällen und Bändern bringen wir den Körper und die Seele in Schwung. Hier finden Sie Linderung für Rückenschmerzen
	und Nackenverspannungen und kräftigende Übungen für den Beckenboden. Sie treffen andere Mütter und haben die
	Möglichkeit, sich  mit ihnen auszutauschen.
	<br>
	Der beste Zeitpunkt mit einem Kurs zu beginnen, ist nach einer Spontangeburt etwa vier Wochen danach. Hatten Sie
	einen Kaiserschnitt, dürfen Sie sich gerne etwas länger Zeit lassen. Grundsätzlich sollten Sie sich wohl fühlen
	und der Babyalltag sich  eingespielt haben.
	Der Kurs findet dienstags 18.00-19.15 Uhr statt und umfasst jeweils 8 Termine.`,

	schedule: [
		"18:00-19:15 Uhr",
		"Zur Zeit pausiere ich den Kurs."
	],

	city: 'Gärtringen',
	address: 'Samariterstift Gärtringen<br>Kirchstraße 17',
	locationImage: 'gaertringen.jpg',

	preview: 'gaertringen_kurs_preview.jpg',
	image: 'gaertringen_kurs.jpg',
}]
