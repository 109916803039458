import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export interface Store {
}

export default new Vuex.Store<Store>({
	modules: {
	},
})
