















































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class App extends Vue {
	@Prop(Boolean) important!: boolean
	@Prop(String) date!: string
}
