










































import { Vue, Component } from 'vue-property-decorator'
import CenterContent from '@/components/CenterContent.vue'

@Component({
	components: {
		CenterContent,
	},
})
export default class App extends Vue {}
