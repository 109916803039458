import Vue from 'vue'
import VueApollo from 'vue-apollo'

import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { onError } from 'apollo-link-error'
import fetch from 'cross-fetch'
import { persistCache } from 'apollo-cache-persist'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'
import { PersistentStorage } from 'apollo-cache-persist/types'

Vue.use(VueApollo)

const httpLink = new HttpLink({
	uri: '/api/internal',
	fetch,
})

const authLink = setContext((context, { headers = {} }) => {
	const token = localStorage.getItem('token')

	if (token) {
		headers.authorization = token
	}

	return {
		headers,
	}
})

const errorLink = onError(({ networkError, graphQLErrors }) => {
	// TODO report to bugsnag
	console.log('GraphQL Error', networkError, graphQLErrors)

	const netError = networkError && networkError.message

	if (netError) {
		alert('Sorry, an error occurred. Please try again.')
	}

	// TODO other errors?
})

const cache = new InMemoryCache()

persistCache({
	cache,
	storage: window.localStorage as PersistentStorage<any>,
})

export const apolloClient = new ApolloClient({
	link: errorLink.concat(authLink.concat(httpLink)),
	ssrMode: !!process.env.SERVER,
	connectToDevTools: true,
	cache,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
			errorPolicy: 'ignore',
		},
		query: {
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		},
	},
})

export const apolloProvider = new VueApollo({
	defaultClient: apolloClient,
})
