import { render, staticRenderFns } from "./CenterContent.vue?vue&type=template&id=2b0c9b7d&scoped=true&"
import script from "./CenterContent.vue?vue&type=script&lang=ts&"
export * from "./CenterContent.vue?vue&type=script&lang=ts&"
import style0 from "./CenterContent.vue?vue&type=style&index=0&id=2b0c9b7d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b0c9b7d",
  null
  
)

component.options.__file = "CenterContent.vue"
export default component.exports