import { render, staticRenderFns } from "./Kontakt.vue?vue&type=template&id=fd8f0bca&scoped=true&"
import script from "./Kontakt.vue?vue&type=script&lang=ts&"
export * from "./Kontakt.vue?vue&type=script&lang=ts&"
import style0 from "./Kontakt.vue?vue&type=style&index=0&id=fd8f0bca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd8f0bca",
  null
  
)

component.options.__file = "Kontakt.vue"
export default component.exports