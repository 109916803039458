
























































import {Vue, Component, Prop} from 'vue-property-decorator'

@Component
export default class App extends Vue {
	@Prop({
		type: String,
		default: 'text',
	})
	type!: string
	@Prop(String) label!: string
	@Prop(String) example!: string
	@Prop(String) value!: string
	@Prop(Boolean) required!: boolean

	currentValue: string = this.value

	update(ev: Event) {
		this.currentValue = (ev.target as HTMLInputElement).value
		this.$emit('input', this.currentValue)
	}
}
