






























































































































































































import { Vue, Component } from 'vue-property-decorator'
import { courses } from '@/lib/courses'
import CenterContent from '@/components/CenterContent.vue'

@Component({
	components: {CenterContent},
})
export default class App extends Vue {
	get course() {
		const slug = (this.$route.params as any).slug as string
		return courses.find(e => e.slug === slug)
	}
	get imageURL() {
		if (this.course) {
			return `url("/img/${this.course.image}")`
		}
	}
	get locationImageURL() {
		if (this.course) {
			return `url("/img/${this.course.locationImage}")`
		}
	}
}
