





































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CenterContent extends Vue {
	@Prop(String) backgroundImage!: string
	@Prop({ type: String, default: '1' }) backgroundOpacity!: string
	@Prop(Boolean) topShadow!: boolean
	@Prop(Boolean) topBox!: boolean

	get imageURL() {
		if (this.backgroundImage) {
			return `url("/img/${this.backgroundImage}")`
		}
	}

	get position() {
		if (this.topBox) {
			return 'absolute'
		} else {
			return 'fixed'
		}
	}
}
