import '@babel/polyfill'
import Vue from 'vue'

import { apolloProvider } from './plugins/graphql'
import { router } from './plugins/router'

import App from './App.vue'
import store from '@/store/store'

Vue.config.productionTip = false

import './global.scss'

new Vue({
	store,
	apolloProvider,
	router,
	render: (h) => h(App),
}).$mount('#app')
