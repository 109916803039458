import { render, staticRenderFns } from "./Philosophie.vue?vue&type=template&id=c32ada9c&scoped=true&"
import script from "./Philosophie.vue?vue&type=script&lang=ts&"
export * from "./Philosophie.vue?vue&type=script&lang=ts&"
import style0 from "./Philosophie.vue?vue&type=style&index=0&id=c32ada9c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c32ada9c",
  null
  
)

component.options.__file = "Philosophie.vue"
export default component.exports