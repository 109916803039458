
















































































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import CenterContent from '@/components/CenterContent.vue'
import HistoryEntry from '@/components/HistoryEntry.vue'
import History from '@/components/History.vue'
import HistorySpacer from '@/components/HistorySpacer.vue'

@Component({
	components: {HistorySpacer, History, HistoryEntry, CenterContent},
})
export default class App extends Vue {}
