




















































































































































































import { Vue, Component } from 'vue-property-decorator'
import TextField from '../components/TextField.vue'
import CenterContent from '@/components/CenterContent.vue'
import axios from 'axios'

const FORM_URL = 'https://formcarry.com/s/vudBauodSeQ'

@Component({
	components: {
		CenterContent,
		TextField,
	},
})
export default class Kontakt extends Vue {
		loading = ''

		name = ''
		wohnort = ''
		entbindungstermin = ''
		telefonnummer = ''
		email = ''
		nachricht = ''

		success = false
		error = false

		async submit(ev: Event) {
			const $form = this.$refs.form as HTMLFormElement
			if (!$form.checkValidity()) {
				console.log('invalid input')
				return
			}
			ev.preventDefault()

			try {
				const form = {
					Name: this.name,
					Wohnort: this.wohnort,
					Entbindungstermin: this.entbindungstermin,
					Telefon: this.telefonnummer,
					Email: this.email,
					Nachricht: this.nachricht,
				}
				console.log('sending: ', form)
				const { data } = await axios.post(FORM_URL, form) as any

				console.log('response', data)

				if (data.status !== 'success') {
					throw new Error('unsuccessful. status: ' + data.success + ' message: ' + data.message)
				}
			} catch (err) {
				console.log(err)
				this.error = true
				return
			}

			this.success = true
		}
}
