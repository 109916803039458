import VueRouter from 'vue-router'
import Vue from 'vue'

import Landing from '@/routes/Landing.vue'
import Kurse from '@/routes/Kurse.vue'
import Kurs from '@/routes/Kurs.vue'
import Kontakt from '@/routes/Kontakt.vue'
import Philosophie from '@/routes/Philosophie.vue'
import About from '@/routes/About.vue'
import Hausbesuche from '@/routes/Hausbesuche.vue'
import Impressum from '@/routes/Impressum.vue'

Vue.use(VueRouter)

const routes = [
	{ path: '/', component: Landing },
	{ path: '/kurse', component: Kurse },
	{ path: '/kurs/:slug', component: Kurs },
	{ path: '/hausbesuche', component: Hausbesuche },
	{ path: '/philosophie', component: Philosophie },
	{ path: '/ueber-mich', component: About },
	{ path: '/kontakt', component: Kontakt },
	{ path: '/Impressum', component: Impressum },
]

export const router = new VueRouter({
	mode: 'history',
	routes,
})
